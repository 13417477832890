import "twin.macro";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { HistoryListItem } from "./history";
import Section, { SectionHashProps } from "./section";

type JobProps = {
  startDate: DateTime;
  endDate?: DateTime;
  title: string;
  company: string;
  city: string;
  country: string;
};

const Job = ({
  startDate,
  endDate,
  title,
  company,
  city,
  country,
}: JobProps) => (
  <HistoryListItem
    startDate={startDate}
    endDate={endDate}
    location={`${city} ${country}`}
    title={title}
    note={company}
  />
);

const WorkHistory = ({ hash }: SectionHashProps) => {
  const { t } = useTranslation();
  return (
    <Section header={t("WorkHistory.Header")} hash={hash}>
      <ol>
        <Job
          startDate={DateTime.fromISO("2020-12")}
          title={t("WorkHistory.SrSystemsDevelopmentEngineer")}
          company="Amazon"
          city={t("Vancouver")}
          country="🇨🇦"
        />
        <Job
          startDate={DateTime.fromISO("2018-06")}
          endDate={DateTime.fromISO("2020-11")}
          title={t("WorkHistory.SystemsDevelopmentEngineer")}
          company="Amazon"
          city={t("Vancouver")}
          country="🇨🇦"
        />
        <Job
          startDate={DateTime.fromISO("2015-01")}
          endDate={DateTime.fromISO("2018-05")}
          title={t("WorkHistory.SolutionsArchitect")}
          company="Amazon"
          city={t("Tokyo")}
          country="🇯🇵"
        />
        <Job
          startDate={DateTime.fromISO("2014-01")}
          endDate={DateTime.fromISO("2014-12")}
          title={t("WorkHistory.SoftwareDevelopmentEngineer")}
          company="DeNA"
          city={t("Tokyo")}
          country="🇯🇵"
        />
        <Job
          startDate={DateTime.fromISO("2012-11")}
          endDate={DateTime.fromISO("2013-12")}
          title={t("WorkHistory.WebOperationEngineer")}
          company="DeNA"
          city={t("San Francisco")}
          country="🇺🇸"
        />
        <Job
          startDate={DateTime.fromISO("2009-04")}
          endDate={DateTime.fromISO("2012-10")}
          title={t("WorkHistory.WebOperationEngineer")}
          company="DeNA"
          city={t("Tokyo")}
          country="🇯🇵"
        />
      </ol>
    </Section>
  );
};

export default WorkHistory;
