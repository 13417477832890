import "twin.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import {
  faTwitterSquare,
  faFacebookSquare,
  faGithubSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../images/riywo.png";
import useHash from "../hooks/use_hash";
import LanguageSelect from "./language_select";

const Logo = () => (
  <div tw="rounded-full ring-4 ring-white overflow-auto w-20 mx-auto">
    <img src={logo} />
  </div>
);

const Name = () => (
  <h1 tw="font-serif font-bold text-3xl p-3">Ryosuke Iwanaga</h1>
);

type SquareIconProps = {
  icon: IconProp;
};
const SquareIcon = ({ icon }: SquareIconProps) => (
  <span className="fa-layers fa-fw">
    <FontAwesomeIcon icon={faSquare} tw="text-white" />
    <FontAwesomeIcon icon={icon} />
  </span>
);

const SNS = () => (
  <div tw="w-60 flex flex-row justify-around text-4xl">
    <a href="https://twitter.com/riywo" target="_blank" rel="noopener">
      <SquareIcon icon={faTwitterSquare} />
    </a>
    <a href="https://facebook.com/riywo" target="_blank" rel="noopener">
      <SquareIcon icon={faFacebookSquare} />
    </a>
    <a href="https://github.com/riywo" target="_blank" rel="noopener">
      <SquareIcon icon={faGithubSquare} />
    </a>
    <a href="https://www.linkedin.com/in/riywo" target="_blank" rel="noopener">
      <SquareIcon icon={faLinkedin} />
    </a>
  </div>
);

type HeroProps = {
  hash: string;
};
const Hero = ({ hash }: HeroProps) => {
  const ref = useHash(hash);
  return (
    <div
      tw="bg-yellow-300 text-gray-900 p-3 w-full flex flex-col items-center"
      ref={ref}
    >
      <div tw="flex flex-row w-full justify-end">
        <div tw="pl-20 flex-grow">
          <Logo />
        </div>
        <div tw="w-20">
          <LanguageSelect />
        </div>
      </div>
      <Name />
      <SNS />
    </div>
  );
};

export default Hero;
