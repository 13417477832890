import "twin.macro";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { HistoryListItem } from "./history";
import Section, { SectionHashProps } from "./section";

type EductaionProps = {
  startDate: DateTime;
  endDate?: DateTime;
  degree: string;
  school: string;
  major: string;
  city: string;
  country: string;
};

const Education = ({
  startDate,
  endDate,
  degree,
  school,
  major,
  city,
  country,
}: EductaionProps) => (
  <HistoryListItem
    startDate={startDate}
    endDate={endDate}
    location={`${city} ${country}`}
    title={degree}
    note={`${major}, ${school}`}
  />
);

const EducationalBackground = ({ hash }: SectionHashProps) => {
  const { t } = useTranslation();
  return (
    <Section header={t("EducationalBackground.Header")} hash={hash}>
      <ul>
        <Education
          startDate={DateTime.fromISO("2007-04")}
          endDate={DateTime.fromISO("2009-03")}
          degree={t("EducationalBackground.CourseCertificates")}
          school={t("EducationalBackground.UniversityOfTokyo")}
          major={t("EducationalBackground.ContentsCreator")}
          city={t("Tokyo")}
          country="🇯🇵"
        />
        <Education
          startDate={DateTime.fromISO("2007-04")}
          endDate={DateTime.fromISO("2009-03")}
          degree={t("EducationalBackground.MasterOfEngineeringDropout")}
          school={t("EducationalBackground.UniversityOfTokyo")}
          major={t("EducationalBackground.MechanoInformaticsGraduate")}
          city={t("Tokyo")}
          country="🇯🇵"
        />
        <Education
          startDate={DateTime.fromISO("2003-04")}
          endDate={DateTime.fromISO("2007-03")}
          degree={t("EducationalBackground.BachelorOfEngineering")}
          school={t("EducationalBackground.UniversityOfTokyo")}
          major={t("EducationalBackground.MechanoInformatics")}
          city={t("Tokyo")}
          country="🇯🇵"
        />
        <Education
          startDate={DateTime.fromISO("2000-04")}
          endDate={DateTime.fromISO("2003-03")}
          degree={t("EducationalBackground.HighSchoolDiploma")}
          school={t("EducationalBackground.YokkaichiHighSchool")}
          major={t("EducationalBackground.GeneralCourse")}
          city={t("Yokkaichi")}
          country="🇯🇵"
        />
      </ul>
    </Section>
  );
};

export default EducationalBackground;
