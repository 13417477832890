import "twin.macro";

const Footer = () => (
  <footer tw="text-xs p-3 border-t-2 w-full text-center">
    &copy; {new Date().getFullYear()} All rights reserved. Built with {` `}
    <a href="https://reactjs.org/">React</a>.
  </footer>
);

export default Footer;
