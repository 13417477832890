import tw from "twin.macro";
import Sticky from "react-sticky-el";
import { openPopupWidget } from "react-calendly";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

const Button = tw.button`
h-10 px-3 m-2 text-sm rounded-lg text-indigo-100 bg-indigo-700 hover:bg-indigo-800
`;

const Calendly = () => {
  const { t } = useTranslation();
  const url = "https://calendly.com/riywo/1on1";
  const onClick = () => openPopupWidget({ url });
  return (
    <Sticky mode="bottom" tw="w-full text-right">
      <Button onClick={onClick}>
        <FontAwesomeIcon icon={faCalendarAlt} tw="mr-2" />
        {t("Calendry.Button")}
      </Button>
    </Sticky>
  );
};

export default Calendly;
