import "twin.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const UnderConstruction = () => {
  const { t } = useTranslation();
  return (
    <div tw="font-bold text-center p-4">
      <FontAwesomeIcon tw="text-yellow-500 mx-2" icon={faExclamationTriangle} />
      {t("UnderConstruction")}...
      <FontAwesomeIcon tw="text-yellow-500 mx-2" icon={faExclamationTriangle} />
    </div>
  );
};

export default UnderConstruction;
