import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { topPosition } from "../components/navbar";

const useHash = (hash: string, sub: boolean = false) => {
  const ref = useRef<HTMLDivElement>(null);
  // useScrollPosition uses throttling technique.
  // Therefore, it won't execute just right before scroll stop.
  // This buffer is for relaxing box detection to capture such lag.
  const buffer = 30;
  const history = useHistory();
  useScrollPosition(
    ({ currPos }) => {
      if (ref.current) {
        const top = ref.current.offsetTop - buffer;
        const bottom = top + ref.current.clientHeight;
        const pos = topPosition(currPos.y, sub);
        if (top <= pos && pos < bottom && location.hash !== hash) {
          history.replace(hash);
        }
      }
    },
    undefined,
    undefined,
    true,
    100
  );
  return ref;
};

export default useHash;
