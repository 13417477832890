import { useTranslation } from "react-i18next";
import FAQCareer from "./faq/faq_career";
import FAQEnglish from "./faq/faq_english";
import FAQHobby from "./faq/faq_hobby";
import FAQImmigration from "./faq/faq_immigration";
import Navbar, { NavbarItem } from "./navbar";
import Section, { SectionHashProps } from "./section";

const FAQ = ({ hash }: SectionHashProps) => {
  const { t } = useTranslation();
  const careerHash = hash + "career/";
  const englishHash = hash + "english/";
  const immigrationHash = hash + "immigration/";
  const hobbyHash = hash + "hobby/";
  return (
    <>
      <Section header="FAQ" hash={hash}></Section>
      <Navbar rootHash={hash}>
        <NavbarItem hash={careerHash}>{t("FAQ.Navbar.Career")}</NavbarItem>
        <NavbarItem hash={englishHash}>{t("FAQ.Navbar.English")}</NavbarItem>
        <NavbarItem hash={immigrationHash}>
          {t("FAQ.Navbar.Immigration")}
        </NavbarItem>
        <NavbarItem hash={hobbyHash}>{t("FAQ.Navbar.Hobby")}</NavbarItem>
      </Navbar>
      <FAQCareer hash={careerHash} />
      <FAQEnglish hash={englishHash} />
      <FAQImmigration hash={immigrationHash} />
      <FAQHobby hash={hobbyHash} />
    </>
  );
};

export default FAQ;
