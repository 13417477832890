import tw from "twin.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeAsia } from "@fortawesome/free-solid-svg-icons";
import Select, { ValueType, components, IndicatorProps } from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const DropdownIndicator = (
  props: IndicatorProps<LanguageOptionType, false>
) => (
  <components.DropdownIndicator tw="p-1!" {...props}>
    <FontAwesomeIcon icon={faGlobeAsia} tw="text-base text-gray-600" />
  </components.DropdownIndicator>
);

const Control = tw(
  components.Control
)`(bg-transparent border-gray-600 text-gray-600 min-h-0)!`;

const SingleValue = tw(components.SingleValue)`text-gray-600!`;

const IndicatorSeparator = tw(components.IndicatorSeparator)`hidden!`;

type LanguageOptionType = {
  value: string;
  label: string;
};
const LanguageSelect = () => {
  const history = useHistory();
  const location = useLocation();
  const { i18n } = useTranslation();
  const options: LanguageOptionType[] = [
    { value: "en", label: "English" },
    { value: "ja", label: "日本語" },
  ];
  const value = options.find((option) => option.value === i18n.language);
  const onChange = (value: ValueType<LanguageOptionType, false>) => {
    if (value?.value) {
      history.push("/" + value.value);
      i18n.changeLanguage();
    }
  };
  useEffect(() => {
    i18n.changeLanguage();
  }, [location]);
  return (
    <Select
      tw="text-xs font-extralight"
      components={{
        DropdownIndicator,
        Control,
        SingleValue,
        IndicatorSeparator,
      }}
      options={options}
      value={value}
      onChange={onChange}
      isClearable={false}
      isSearchable={false}
    />
  );
};

export default LanguageSelect;
