import tw from "twin.macro";
import { PropsWithChildren } from "react";

const Button = tw.div`mx-1 shadow-inner rounded-full w-3 h-3`;
const ButtonRed = tw(Button)`border-red-900 bg-red-500`;
const ButtonYellow = tw(Button)`border-yellow-900 bg-yellow-500`;
const ButtonGreen = tw(Button)`border-green-900 bg-green-500`;

type BarProps = {
  title: string;
};
const Bar = ({ title }: BarProps) => (
  <div
    tw="
  flex items-center h-6 px-1 rounded-t-lg 
  bg-gray-100 border-b border-gray-500 shadow-2xl
  text-center text-black"
  >
    <ButtonRed />
    <ButtonYellow />
    <ButtonGreen />
    <div tw="mx-auto pr-16">
      <p tw="text-center text-sm">{title}</p>
    </div>
  </div>
);

export const Prompt = ({ children }: PropsWithChildren<{}>) => (
  <p>
    <span tw="text-green-500">➜ </span>
    <span tw="text-blue-400">~ </span>
    {children}
  </p>
);

type TerminalProps = PropsWithChildren<{
  title: string;
}>;
const Terminal = ({ title, children }: TerminalProps) => (
  <div tw="w-full subpixel-antialiased max-h-full">
    <Bar title={title} />
    <div
      tw="
    p-2 h-56
    text-white font-mono text-xs break-all leading-relaxed
    bg-black rounded-b-lg shadow-lg"
    >
      {children}
    </div>
  </div>
);

export default Terminal;
