import "twin.macro";
import { DateTime } from "luxon";
import Time from "./time";

type HistoryTimeProps = {
  startDate: DateTime;
  endDate?: DateTime;
};

const HistoryTime = ({ startDate, endDate }: HistoryTimeProps) => (
  <div tw="flex flex-row">
    <Time dateTime={startDate} options={{ month: "short", year: "numeric" }} />
    <span tw="px-1">-</span>
    {endDate && (
      <Time dateTime={endDate} options={{ month: "short", year: "numeric" }} />
    )}
  </div>
);

type HistoryListItemProps = {
  startDate: DateTime;
  endDate?: DateTime;
  location: string;
  title: string;
  note?: string;
};

export const HistoryListItem = ({
  startDate,
  endDate,
  location,
  title,
  note,
}: HistoryListItemProps) => (
  <li tw="py-2 border-b-2">
    <div tw="text-xs flex flex-row justify-between">
      <HistoryTime startDate={startDate} endDate={endDate} />
      <div>{location}</div>
    </div>
    <div tw="font-bold">{title}</div>
    <div tw="text-sm">{note}</div>
  </li>
);
