import tw from "twin.macro";
import Sticky from "react-sticky-el";
import { NavHashLink } from "react-router-hash-link";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const navbarHeight = 38;
const navbarExtraOffset = 10; // Needs some room to avoid unecessary margin.

const isHashMatched = (hash: string) => {
  return hash === "#" ? location.hash === "" : location.hash.startsWith(hash);
};

export const topPosition = (scrollY: number, sub: boolean = false) => {
  const topOffset = sub ? navbarHeight * 2 : navbarHeight;
  return scrollY + topOffset;
};

type NavbarAnchorProps = PropsWithChildren<{
  hash: string;
  sub?: boolean;
}>;
export const NavbarAnchor = ({
  hash,
  sub = false,
  children,
}: NavbarAnchorProps) => {
  const id = hash.substring(1);
  const ref = useRef<HTMLAnchorElement>(null);
  const href = "/" + hash;
  const topOffset = (sub ? navbarHeight * 2 : navbarHeight) + 40;
  useEffect(() => {
    if (location.hash === hash) {
      ref.current?.scrollIntoView({ block: "start" });
    }
  }, []);
  return (
    <a id={id} ref={ref} href={href} style={{ paddingTop: topOffset }}>
      {children}
    </a>
  );
};

type NavbarItemProps = PropsWithChildren<{
  hash: string;
}>;
export const NavbarItem = ({ hash, children }: NavbarItemProps) => {
  const activeStyle = tw`border-gray-700`;
  const [style, setStyle] = useState({});
  const changeStyle = () =>
    isHashMatched(hash) ? setStyle(activeStyle) : setStyle({});
  useEffect(changeStyle, []);
  useScrollPosition(changeStyle);
  return (
    <NavHashLink
      tw="flex-1 text-center font-bold text-xs text-gray-700 uppercase
      border-transparent border-b-4 pt-1
      h-full flex items-center justify-center"
      to={hash}
      style={style}
    >
      {children}
    </NavHashLink>
  );
};

type NavbarProps = PropsWithChildren<{
  rootHash?: string;
}>;
const Navbar = ({ rootHash, children }: NavbarProps) => {
  // This is a workaround of a race of Sticky.
  // Sometimes, Sticky components is recognized as "fixed" after page reload
  // only when topOffset is a negative value.
  // By adding 500 ms delay before enabling stickness, it won't happen.
  // Probaly it's caused when this calculation happens before the full rendering:
  // https://github.com/gm0t/react-sticky-el/blob/360b1c74d6a2866bd25664b8ba41f7d0c05aba31/src/render-props-version.js#L240
  const [disabled, setDisabled] = useState<boolean>(true);
  useEffect(() => {
    setTimeout(() => {
      setDisabled(false);
    }, 500);
  }, []);
  if (rootHash) {
    useScrollPosition(() => {
      if (location.hash.startsWith(rootHash)) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    });
  }
  const stickyProps =
    rootHash === undefined
      ? {
          disabled: disabled,
          topOffset: -navbarExtraOffset,
        }
      : {
          disabled: disabled,
          stickyStyle: { marginTop: navbarHeight },
          topOffset: -navbarHeight - navbarExtraOffset,
        };
  return (
    <Sticky tw="w-full" {...stickyProps}>
      <nav tw="bg-yellow-200 shadow-md w-full" style={{ height: navbarHeight }}>
        <div tw="flex justify-center h-full items-center mx-auto max-w-lg">
          {children}
        </div>
      </nav>
    </Sticky>
  );
};

export default Navbar;
