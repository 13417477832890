import "twin.macro";
import { DateTime, LocaleOptions, DateTimeFormatOptions } from "luxon";
import useLocale from "../hooks/use_locale";

type TimeProps = {
  dateTime: DateTime;
  options?: LocaleOptions & DateTimeFormatOptions;
};

const Time = ({ dateTime, options }: TimeProps) => {
  const locale = useLocale();
  return (
    <time tw="block">{dateTime.setLocale(locale).toLocaleString(options)}</time>
  );
};

export default Time;
