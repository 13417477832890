import "twin.macro";
import { useTranslation } from "react-i18next";
import Section, { SectionHashProps } from "../section";
import UnderConstruction from "../under_construction";

const FAQHobby = ({ hash }: SectionHashProps) => {
  const { t } = useTranslation();
  return (
    <Section sub header={t("FAQ.Hobby.Header")} hash={hash}>
      <dl tw="h-96">
        <UnderConstruction />
      </dl>
    </Section>
  );
};
export default FAQHobby;
