import tw from "twin.macro";
import { PropsWithChildren } from "react";
import useHash from "../hooks/use_hash";
import { NavbarAnchor } from "./navbar";

const Header = tw.h2`text-center text-2xl p-2`;
const SubHeader = tw.h3`text-center text-xl p-2`;

export type SectionHashProps = {
  hash: string;
};

type SectionProps = PropsWithChildren<
  {
    header: string;
    sub?: boolean;
  } & SectionHashProps
>;
const Section = ({ hash, header, sub = false, children }: SectionProps) => {
  const ref = useHash(hash, sub);
  const anchor = (
    <NavbarAnchor hash={hash} sub={sub}>
      {header}
    </NavbarAnchor>
  );
  return (
    <div ref={ref} tw="w-full p-2 pt-6 max-w-lg">
      {sub ? <SubHeader>{anchor}</SubHeader> : <Header>{anchor}</Header>}
      {children}
    </div>
  );
};

export default Section;
