import "twin.macro";
import { useTranslation } from "react-i18next";
import Section, { SectionHashProps } from "../section";
import QA from "./q_and_a";
import UnderConstruction from "../under_construction";

const FAQCareer = ({ hash }: SectionHashProps) => {
  const { t } = useTranslation();
  return (
    <Section sub header={t("FAQ.Career.Header")} hash={hash}>
      <dl tw="h-96">
        <QA
          Q={t("FAQ.Career.WhyDeveloper.Q")}
          A={t("FAQ.Career.WhyDeveloper.A")}
        />
        <UnderConstruction />
      </dl>
    </Section>
  );
};

export default FAQCareer;
