import "twin.macro";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import Section, { SectionHashProps } from "./section";
import UnderConstruction from "./under_construction";

type ItemProps = {
  title: string;
  badge?: string;
  url: string;
  description: string;
};
const Item = ({ title, badge, url, description }: ItemProps) => (
  <>
    <dt tw="list-item ml-4 pb-2 pt-4">
      <a href={url} target="_blank" rel="noopener" tw="border-b-2 border-black">
        {title}
      </a>
      <FontAwesomeIcon icon={faExternalLinkAlt} tw="text-xs mx-2 inline" />
      {badge && <img src={badge} tw="inline" />}
    </dt>
    <dd tw="ml-6 text-sm">{description}</dd>
  </>
);

const Output = ({ hash }: SectionHashProps) => {
  const { t } = useTranslation();
  return (
    <Section header={t("Output.Header")} hash={hash}>
      <dl tw="p-2 list-disc">
        <Item
          title={t("Output.JapaneseBlog.Title")}
          url="https://blog.riywo.com"
          description={t("Output.JapaneseBlog.Description")}
        />
        <Item
          title={t("Output.Anyenv.Title")}
          badge="https://img.shields.io/github/stars/anyenv/anyenv?style=social"
          url="https://github.com/anyenv/anyenv"
          description={t("Output.Anyenv.Description")}
        />
        <Item
          title={t("Output.Medium.Title")}
          url="https://riywo.medium.com"
          description={t("Output.Medium.Description")}
        />
        <Item
          title={t("Output.Dev.Title")}
          url="https://dev.to/riywo"
          description={t("Output.Dev.Description")}
        />
        <Item
          title={t("Output.OldTechBlog.Title")}
          url="https://tech.riywo.com"
          description={t("Output.OldTechBlog.Description")}
        />
        <Item
          title={t("Output.OldEnglishBlog.Title")}
          url="https://weblog.riywo.com"
          description={t("Output.OldEnglishBlog.Description")}
        />
      </dl>
      <div tw="h-48">
        <UnderConstruction />
      </div>
    </Section>
  );
};

export default Output;
