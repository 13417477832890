import "twin.macro";
import Meta from "./meta";
import Hero from "./hero";
import Footer from "./footer";
import Calendly from "./calendly";
import Navbar, { NavbarItem } from "./navbar";
import Summary from "./summary";
import WorkHistory from "./work_history";
import EducationalBackground from "./educational_background";
import FAQ from "./faq";
import Output from "./output";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBriefcase,
  faGraduationCap,
  faQuestion,
  faPenFancy,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type IconProps = {
  icon: IconProp;
};
const Icon = ({ icon }: IconProps) => (
  <FontAwesomeIcon tw="text-xl" icon={icon} />
);

const Home = () => {
  const rootHash = "#";
  const workHash = "#work/";
  const educationHash = "#education/";
  const faqHash = "#faq/";
  const outputHash = "#output/";
  return (
    <div tw="flex flex-auto flex-col items-center w-screen">
      <Meta />
      <Hero hash={rootHash} />
      <Navbar>
        <NavbarItem hash={rootHash}>
          <Icon icon={faHome} />
        </NavbarItem>
        <NavbarItem hash={workHash}>
          <Icon icon={faBriefcase} />
        </NavbarItem>
        <NavbarItem hash={educationHash}>
          <Icon icon={faGraduationCap} />
        </NavbarItem>
        <NavbarItem hash={faqHash}>
          <Icon icon={faQuestion} />
        </NavbarItem>
        <NavbarItem hash={outputHash}>
          <Icon icon={faPenFancy} />
        </NavbarItem>
      </Navbar>
      <Summary hash={rootHash} />
      <WorkHistory hash={workHash} />
      <EducationalBackground hash={educationHash} />
      <FAQ hash={faqHash} />
      <Output hash={outputHash} />
      <Calendly />
      <Footer />
    </div>
  );
};

export default Home;
