import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const publicUrl = new URL(
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : process.env.PUBLIC_URL
);

const canonicalUrl = (lng: string) => {
  const path = lng === "en" ? "/" : `/${lng}/`;
  return new URL(path, publicUrl);
};

const Meta = () => {
  const { i18n } = useTranslation();
  return (
    <Helmet>
      <html lang={i18n.language} />
      <link rel="alternate" hrefLang="en" href={canonicalUrl("en").href} />
      <link rel="alternate" hrefLang="ja" href={canonicalUrl("ja").href} />
      <link rel="canonical" href={canonicalUrl(i18n.language).href} />
      <script
        defer
        data-domain={publicUrl.hostname}
        src="https://plausible.io/js/plausible.hash.outbound-links.js"
      />
    </Helmet>
  );
};

export default Meta;
