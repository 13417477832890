import "twin.macro";
import { useTranslation } from "react-i18next";
import Terminal, { Prompt } from "./terminal";
import useHash from "../hooks/use_hash";

type SummaryProps = {
  hash: string;
};
const Summary = ({ hash }: SummaryProps) => {
  const ref = useHash(hash);
  const { t } = useTranslation();
  return (
    <div ref={ref} tw="w-full px-2 py-3 max-w-lg">
      <Terminal title="riywo">
        <Prompt>whoami</Prompt>
        {t("riywo")}
        <Prompt>where riywo</Prompt>
        {t("Vancouver")} 🇨🇦
        <Prompt>cat /dev/riywo</Prompt>
        {t("Summary")}
      </Terminal>
    </div>
  );
};

export default Summary;
