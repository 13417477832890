import { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Home from "./components/home";
import "./i18n";

const App = () => (
  <BrowserRouter>
    <Suspense fallback="loading">
      <Switch>
        <Route exact strict path={["/", "/ja/"]}>
          <Home />
        </Route>
        <Route exact path="/en">
          <Redirect to="/" />
        </Route>
        <Route exact strict path="/ja">
          <Redirect to="/ja/" />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </Suspense>
  </BrowserRouter>
);

export default App;
