import "twin.macro";

type QAProps = {
  Q: string;
  A: string;
};
const QA = ({ Q, A }: QAProps) => (
  <>
    <dt tw="font-bold">Q: {Q}</dt>
    <dd tw="pb-4">{A}</dd>
  </>
);

export default QA;
